import merge from 'lodash/merge';

import { fetchJsonWithParsedError, buildQueryString } from 'common/http';
import { Domain, SearchParameters, Users } from 'organizationDashboard/types';

export const fetchOrganizationUsersPage = (params: SearchParameters): Users => {
  return fetchJsonWithParsedError(`/api/organizations/current/users?${buildQueryString(params)}`);
};

export const fetchOrganizationUserPageWithCount = (params: SearchParameters): Users => {
  const newParams = merge({}, params, { includeCount: true });
  return fetchOrganizationUsersPage(newParams);
};

export const filterSitesWithoutDashboard = (sites: Domain[]): Domain[] => {
  return sites.filter((site): boolean => !(site.flags || []).includes('organizationDashboard'));
};

export const areAssetsLoaded = (asset: number): boolean => asset < 0;
